import * as React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { IntlLink } from '~/components/shared/Link';
import linkMessages from '~/links/messages';

const RecaptchaTerms = () => {
  const i18nIdPrefix = 'recaptcha';
  const intl = useIntl();
  const locale = intl.locale;

  if (locale === 'ja') {
    return (
      <>
        <FormattedMessage id={'ff_recaptcha.part1'} />
        <IntlLink hrefTranslation={linkMessages.recaptchaTerms}>
          <FormattedMessage id={'ff_recaptcha.termsLinkText'} />
        </IntlLink>
        <FormattedMessage id={'ff_recaptcha.part2'} />
      </>
    );
  }

  return (
    <>
      <FormattedMessage id={`${i18nIdPrefix}.part1`} />
      <IntlLink hrefTranslation={linkMessages.recaptchaPrivacy}>
        <FormattedMessage id={`${i18nIdPrefix}.privacyLinkText`} />
      </IntlLink>
      <FormattedMessage id={`${i18nIdPrefix}.part2`} />
      <IntlLink hrefTranslation={linkMessages.recaptchaTerms}>
        <FormattedMessage id={`${i18nIdPrefix}.termsLinkText`} />
      </IntlLink>
      <FormattedMessage id={`${i18nIdPrefix}.part3`} />
    </>
  );
};

export default RecaptchaTerms;
