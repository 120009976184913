import { MenuItem } from '@circleci/compass';
import { BaseButton } from '@circleci/compass/dist/rac/Buttons';
import { UnstyledLink } from '@circleci/compass/dist/rac/Links';
import { theme } from '@circleci/compass/dist/theme';
import styled from '@emotion/styled';
import { Group, Separator as AriaSeparator } from 'react-aria-components';

export const ButtonGroup = styled(Group)`
  display: inline-flex;
  width: fit-content;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 0;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: ${theme.radii.circle};
  outline: none;
  box-sizing: border-box;
  height: ${theme.space.space40};
  font-size: ${theme.fontSizes.fontsize14};
  font-weight: ${theme.fontWeights.medium};
  background: ${theme.colors.blue500};
  border: ${theme.borderWidths.regular} solid ${theme.colors.blue600};
  color: ${theme.colors.white};

  &[data-disabled] {
    opacity: ${theme.opacity.semiOpaque};
    cursor: not-allowed;
  }
`;
export const LoginButton = styled(UnstyledLink)`
  display: inline-flex;
  width: fit-content;
  text-decoration: none;
  padding: ${theme.space.space8} ${theme.space.space16};
  justify-content: center;
  align-items: center;
  gap: ${theme.space.space8};
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: ${theme.radii.circle} ${theme.radii.hard} ${theme.radii.hard}
    ${theme.radii.circle};
  outline: none;
  font-size: ${theme.fontSizes.fontsize14};
  line-height: ${theme.lineHeights.lineheight24};
  font-weight: ${theme.fontWeights.medium};

  height: ${theme.space.space40};
  background: transparent;
  border: none;
  color: ${theme.colors.white};
  padding-right: ${theme.space.space8};

  &[data-hovered] {
    background: ${theme.colors.blue600};
  }

  &[data-pressed] {
    background: ${theme.colors.blue700};
  }

  &[data-focus-visible] {
    outline: ${theme.borderWidths.thick} solid ${theme.colors.blue400};
    outline-offset: ${theme.space.space2};
  }
`;

export const DropdownButton = styled(BaseButton)`
  border-top-left-radius: ${theme.radii.hard};
  border-bottom-left-radius: ${theme.radii.hard};
  background: transparent;
  border: none;
  color: ${theme.colors.white};
  padding-left: ${theme.space.space8};
  &[data-hovered] {
    background: ${theme.colors.blue600};
  }

  &[data-pressed] {
    background: ${theme.colors.blue700};
  }

  &[data-focus-visible] {
    outline: ${theme.borderWidths.thick} solid ${theme.colors.blue400};
    outline-offset: ${theme.space.space2};
  }
`;

export const Separator = styled(AriaSeparator)`
  width: ${theme.borderWidths.regular};
  height: ${theme.space.space24};
  background: ${theme.colors.white};
`;

export const MenuLink = styled(MenuItem)`
  display: block;
  text-decoration: none;
  color: ${theme.colors.n900};
`;
