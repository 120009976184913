import * as React from 'react';

import { useTrackAction } from '@circleci/analytics';
import { GitLabIcon, PrimaryButton } from '@circleci/compass';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import ButtonContents from './ButtonContents';

interface GitlabButtonProps {
  textId: string;
  destination: string;
}

const AuthButtonGitlab = ({
  textId,
  destination,
}: GitlabButtonProps): React.ReactElement => {
  const router = useRouter();

  const trackButton = useTrackAction({
    context: 'authentication',
    object: 'button',
    eventProperties: {
      button: 'gitlab',
    },
  });

  const handleClick = (): void => {
    trackButton('clicked', {}, { server: true });
    Cookies.set('gitlab-onboarding', 'true', {
      domain: '.circleci.com',
      path: '/',
    });
    router.push(destination);
  };

  return (
    <PrimaryButton
      data-optimizely='gitlab-button'
      onPress={handleClick}
    >
      <ButtonContents
        Icon={GitLabIcon}
        textId={textId}
        iconSize='24px'
      />
    </PrimaryButton>
  );
};

export default AuthButtonGitlab;
