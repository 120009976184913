import * as React from 'react';

import {
  useCompassTheme,
  CompassTheme,
  PrimaryButton,
} from '@circleci/compass';
import styled from '@emotion/styled';
import auth0, { Auth0Error } from 'auth0-js';
import { useGoogleReCaptchaV2 } from 'react-google-recaptcha-hooks';
import { FormattedMessage, useIntl } from 'react-intl';

import * as colors from '~/components/shared/colors';
import { assetPrefix } from '~/pages/_document';

import ButtonContents from './AuthButtons/ButtonContents';

const StyledForm = styled.form<{ theme: CompassTheme }>`
  display: none;
  color: ${colors.black100};
  text-align: left;

  label {
    display: block;
    font-size: ${(props): string => {
      return props.theme.fontSizes.fontsize14;
    }};
    margin-bottom: ${(props): string => {
      return props.theme.space.space4;
    }};
  }

  .show-signup-form & {
    display: block;
  }
`;

const StyledInput = styled.input<{ theme: CompassTheme }>`
  border: 1px solid #c0c0c0;
  border-radius: 3px;
  height: 44px;
  padding: ${(props): string => {
    return props.theme.space.space8;
  }};
  width: 100%;
  margin-bottom: ${(props): string => {
    return props.theme.space.space12;
  }};

  &:invalid.error {
    border: 2px solid ${colors.red500};
  }
`;

const PasswordBox = styled.div`
  position: relative;

  img {
    position: absolute;
    top: 18px;
    right: 10px;
    cursor: pointer;
  }
`;

const Instructions = styled.span<{ theme: CompassTheme }>`
  display: block;
  color: #6a6a6a;
  font-size: ${(props): string => {
    return props.theme.fontSizes.fontsize12;
  }};
  line-height: ${(props): string => {
    return props.theme.lineHeights.lineheight20;
  }};
  margin-bottom: ${(props): string => {
    return props.theme.fontSizes.fontsize12;
  }};

  input:invalid + &.error {
    color: ${colors.red500};
  }
`;

const StyledDivider = styled.div<{ theme: CompassTheme }>`
  display: flex;
  margin: ${(props): string => {
    return `${props.theme.space.space20} 0 ${props.theme.space.space24}`;
  }};

  hr {
    border: 1px solid #f3f3f3;
    flex: 1;
  }

  p {
    margin: ${(props): string => {
      return `0 ${props.theme.space.space12}`;
    }};
  }
`;

const SignupForm = (): React.ReactElement => {
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const emailRef = React.useRef<HTMLInputElement | null>(null);
  const passwordRef = React.useRef<HTMLInputElement | null>(null);
  const intl = useIntl();
  const { executeReCaptcha, resetReCaptcha } = useGoogleReCaptchaV2({
    siteKey: process.env.RECAPTCHA_V2_SITE_KEY ?? '',
    language: intl.locale,
  });
  const theme = useCompassTheme();

  const signUp = (): void => {
    const emailValue = emailRef.current?.value;
    const passwordValue = passwordRef.current?.value;
    const nodeEnv = process.env.NODE_ENV;
    const params = {
      domain: process.env.NEXT_PUBLIC_AUTH0_STAGING_DOMAIN,
      clientID: process.env.NEXT_PUBLIC_AUTH0_STAGING_CLIENT_ID,
    };

    if (nodeEnv === 'production') {
      params.domain = process.env.NEXT_PUBLIC_AUTH0_PRODUCTION_DOMAIN;
      params.clientID = process.env.NEXT_PUBLIC_AUTH0_PRODUCTION_CLIENT_ID;
    }

    const webAuth = new auth0.WebAuth({
      domain: params.domain ?? '',
      clientID: params.clientID ?? '',
      responseType: 'code',
    });

    webAuth.redirect.signupAndLogin(
      {
        connection: process.env.NEXT_PUBLIC_AUTH0_CONNECTION ?? '',
        redirectUri: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URL,
        email: emailValue ?? '',
        password: passwordValue ?? '',
      },
      (err: Auth0Error | null) => {
        if (err) {
          if (err.name === 'PasswordStrengthError') {
            setPasswordError(true);
          }

          // eslint-disable-next-line no-console
          return console.error('Something went wrong: ' + err.error);
        }

        // eslint-disable-next-line no-console
        return console.log('success signup without login!');
      },
    );
  };

  const handleCaptcha = async (): Promise<void> => {
    const token = await executeReCaptcha();

    if (token) {
      signUp();
    }

    setTimeout(() => {
      resetReCaptcha();
    }, 3000);
  };

  const handleClick = (e): void => {
    e.preventDefault();

    const isEmail = emailRef.current?.checkValidity();
    const passwordValue = passwordRef.current?.checkValidity();

    setEmailError(!isEmail);
    setPasswordError(!passwordValue);

    if (!isEmail || !passwordValue) {
      return;
    }

    // if email and password pass validation,
    // continue with signup and captcha
    handleCaptcha();
  };

  return (
    <StyledForm theme={theme}>
      <label htmlFor='email'>
        <FormattedMessage id='signup.hero.emailLabel' />
      </label>
      <StyledInput
        theme={theme}
        type='email'
        name='email'
        autoComplete='username'
        inputMode='email'
        className={emailError ? 'error' : ''}
        required
        pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
        ref={emailRef}
      />
      {emailError && (
        <Instructions
          theme={theme}
          className='error'
        >
          <FormattedMessage id='signup.hero.emailInstructions' />
        </Instructions>
      )}

      <label htmlFor='password'>
        <FormattedMessage id='signup.hero.passwordLabel' />
      </label>
      <PasswordBox theme={theme}>
        <StyledInput
          type={passwordVisible ? 'text' : 'password'}
          name='password'
          autoComplete='new-password'
          className={passwordError ? 'error' : ''}
          ref={passwordRef}
          required
          minLength={10}
        />
        {!passwordError && (
          <Instructions theme={theme}>
            <FormattedMessage id='signup.hero.passwordInstructions' />
          </Instructions>
        )}
        {passwordError && (
          <Instructions
            theme={theme}
            className='error'
          >
            <FormattedMessage id='signup.hero.passwordErrorMsg' />
          </Instructions>
        )}
        <img
          className='hide-password-icon'
          height='11'
          width='13'
          src={`${assetPrefix}/static/images/${
            passwordVisible ? 'hide' : 'show'
          }-password-icon.svg`}
          onClick={(): void => {
            setPasswordVisible(!passwordVisible);
          }}
        />
      </PasswordBox>

      <PrimaryButton
        className='email-btn'
        data-optimizely='email-button-no-icon'
        onPress={handleClick}
      >
        <ButtonContents textId={`signup.hero.emailBtn`} />
      </PrimaryButton>

      <StyledDivider theme={theme}>
        <hr />
        <p>
          <FormattedMessage id='signup.hero.dividerText' />
        </p>
        <hr />
      </StyledDivider>
    </StyledForm>
  );
};

export default SignupForm;
