import * as React from 'react';

import { CancelIcon, useCompassTheme } from '@circleci/compass';
import { css } from 'emotion';
import Modal from 'react-modal';

import styled from '../../styled';

const overlayStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.div(({ theme }) => {
  return css`
    position: absolute;
    top: ${theme.space.space16};
    right: ${theme.space.space16};
    cursor: pointer;
    color: ${theme.colors.white};
    transition: opacity 200ms;
    border-radius: 50%;

    @media (min-width: ${theme.mediaQueries.small}) {
      top: ${theme.space.space24};
      right: ${theme.space.space24};
    }

    &:hover {
      opacity: 0.7;
    }
  `;
});

export default ({
  children,
  isOpen,
  onOpen,
  onClose,
  className,
  styles,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
  styles?: string;
}) => {
  const theme = useCompassTheme();
  const modalStyles = css`
    border: 1px solid ${theme.colors.n70};
    background: ${theme.colors.white};
    overflow: visible;
    border-radius: ${theme.space.space4};
    outline: none;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: ${theme.mediaQueries.small}) {
      max-width: 820px;
      height: fit-content;
      max-height: 100vh;
    }
  `;
  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onOpen}
      onRequestClose={onClose}
      className={`${modalStyles.toString()} ${className} ${
        styles && styles.toString()
      }`}
      overlayClassName={overlayStyles.toString()}
      closeTimeoutMS={200}
    >
      <CloseButton onClick={onClose}>
        <CancelIcon />
      </CloseButton>
      {children}
    </Modal>
  );
};
