import * as React from 'react';

import { useTrackAction } from '@circleci/analytics';
import { PrimaryButtonLink } from '@circleci/compass';
import { IconComponent } from '@circleci/compass/dist/types';

import linkMessages from '~/links/messages';

import { UseTranslatedHref, trackButtonAction } from '../shared/Link';
import ButtonContents from './ButtonContents';

interface SignupButtonProps {
  textId: string;
  query: Map<string, string>;
  Icon?: IconComponent;
  isCenter?: boolean;
}

const AuthButtonSignup = ({
  textId,
  query,
  Icon,
}: SignupButtonProps): React.ReactElement => {
  // Conditional URL for login vs sign up pages. GH and BB authentication
  // uses the same URL for login and sign up which is why this is only needed
  // for auth0 auth.
  const authURL = linkMessages.authSignUp;
  const translatedAuthURL = UseTranslatedHref(authURL, undefined, query);
  const trackLink = useTrackAction({
    context: 'authentication',
    object: 'button',
    eventProperties: {
      button: 'sign_up',
      translatedAuthURL,
    },
  });
  const handleSignUpCTA = () => {
    trackLink('clicked');
    trackButtonAction(translatedAuthURL);
  };
  return (
    <PrimaryButtonLink
      className='signup-btn'
      data-optimizely='signup-button'
      data-testid='auth-signup-button'
      href={UseTranslatedHref(authURL, undefined, query)}
      onPress={handleSignUpCTA}
    >
      <ButtonContents
        Icon={Icon}
        textId={textId}
      />
    </PrimaryButtonLink>
  );
};

export default AuthButtonSignup;
