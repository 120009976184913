import * as React from 'react';

import { useCompassTheme } from '@circleci/compass';
import { IconComponent } from '@circleci/compass/dist/types';
import { FormattedMessage } from 'react-intl';

import styled from '~/styled';

interface ButtonContentsProps {
  Icon?: IconComponent;
  textId: string;
  iconSize?: string;
}

export const ButtonLinkIconContainer = styled.div`
  display: grid;
  align-items: center;
`;

const ButtonContents = ({ Icon, textId, iconSize }: ButtonContentsProps) => {
  const theme = useCompassTheme();

  return (
    <>
      {Icon && (
        <ButtonLinkIconContainer>
          <Icon
            size={iconSize || theme.fontSizes.fontsize24}
            color='currentColor'
          />
        </ButtonLinkIconContainer>
      )}
      <FormattedMessage id={textId} />
    </>
  );
};

export default ButtonContents;
