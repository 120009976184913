import * as React from 'react';

interface Size {
  size: number;
}

export default ({ size }: Size) => {
  return (
    <svg
      height={size}
      viewBox='0 0 20 20'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m15.1039344 10.1170492-8.13803276 4.8777049c-.10918033.0655738-.24819672-.0131148-.24819672-.1406557v-9.75573774c0-.12721312.13901639-.20590164.24819672-.14032787l8.13803276 4.87770491c.1062295.06360656.1062295.2177049 0 .2813115m-5.12754096-10.1170492c-5.50983606 0-9.97639344 4.46655738-9.97639344 9.97639344s4.46655738 9.97639346 9.97639344 9.97639346 9.97639346-4.4665574 9.97639346-9.97639346-4.4665574-9.97639344-9.97639346-9.97639344'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
};
