import * as React from 'react';
import { useEffect } from 'react';

import { useTrackAction } from '@circleci/analytics';
import { useTrackExperimentEntry } from '@circleci/react-experiments';
import { useRouter } from 'next/router';

import ContentWrapper from '~/components/ContentWrapper';
import Footer from '~/components/Footer';
import Header from '~/components/Header';
import * as screenSizes from '~/components/shared/screenSizes';
import styled from '~/styled';

const Inner = styled.div(({ theme }) => {
  return `
    color: ${theme.colors.white};
    position: relative;
    background-image: linear-gradient(
      to right,
      ${theme.colors.cg800} 62%,
      white 62%
    );
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;

    @media (max-width: ${screenSizes.large}) {
      background-image: none;
    }
  `;
});

interface Props {
  children: React.ReactNode;
  page: 'signup' | 'vcsAuthorize';
}

export default ({ children, page }: Props) => {
  const router = useRouter();
  const trackRedirectFromAuth0 = useTrackAction({
    object: 'redirectFromAuth0',
  });
  useEffect(() => {
    const returnsource = router.query['returnsource'];

    if (returnsource === 'auth0-signup') {
      // The below 'auth0-signup' event is returned from the auth0 signup page.
      // This is sent back when the user clicks the return to CircleCI button on the signup page.
      // Here we capture the event and track it.
      trackRedirectFromAuth0('redirected');
    }
  }, [router.query, trackRedirectFromAuth0]);

  useTrackExperimentEntry({ enabled: page === 'signup' });

  return (
    <>
      <Header page={page} />
      <Inner>
        <ContentWrapper>{children}</ContentWrapper>
      </Inner>
      <Footer />
    </>
  );
};
