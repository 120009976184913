import React, { useState } from 'react';

import { useTrackAction } from '@circleci/analytics';
import {
  Flex,
  ArrowDownIcon,
  ArrowUpIcon,
  EmailIcon,
  PrimaryButton,
  PrimaryButtonLink,
  CircleLogoIcon,
} from '@circleci/compass';
import { useRouter } from 'next/router';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  getQueryParams,
  UseTranslatedHref,
  setQueryParam,
  trackButtonAction,
} from '~/components/shared/Link';
import linkMessages from '~/links/messages';
import styled from '~/styled';

import AuthButtonBitbucket from './AuthButtons/AuthButtonBitbucket';
import AuthButtonGitHub from './AuthButtons/AuthButtonGitHub';
import ButtonContents from './AuthButtons/ButtonContents';

interface LogInCtaFlowProps {
  textId: string;
  query: Map<string, string>;
  displayLogInOptions: boolean;
  setDisplayLogInOptions: () => void;
}

const LogInCtaFlow = ({
  textId,
  query,
  displayLogInOptions,
  setDisplayLogInOptions,
}: LogInCtaFlowProps) => {
  const trackAuthenticationButton = useTrackAction({
    context: 'authentication',
    object: 'button',
    eventProperties: {
      button: 'email_pw',
    },
  });
  const handleClick = () => {
    setDisplayLogInOptions();
    trackAuthenticationButton('clicked', { button: 'log_in' });
  };

  return displayLogInOptions ? (
    <PrimaryButtonLink
      className='email-btn'
      data-optimizely='email-button'
      href={UseTranslatedHref(linkMessages.authLogin, undefined, query)}
      onPress={trackButtonAction(
        UseTranslatedHref(linkMessages.authLogin, undefined, query),
        trackAuthenticationButton,
      )}
    >
      <ButtonContents
        Icon={EmailIcon}
        textId={textId}
      />
    </PrimaryButtonLink>
  ) : (
    <PrimaryButton
      data-testid='login-btn'
      onPress={handleClick}
    >
      <ButtonContents
        Icon={CircleLogoIcon}
        textId={'vcsAuthorized.hero.logInBtn'}
      />
    </PrimaryButton>
  );
};

const LegacyVcsDropdownTitle = styled(Flex)(({ theme }) => {
  return `
    color: ${theme.colors.blue500};
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.fontsize14};
    line-height: 1.4;
    text-align: left;
    cursor: pointer;
    font-variant-numeric: lining-nums proportional-nums slashed-zero;
    margin-top: 32px;
`;
});

const StyledArrowUp = styled(ArrowUpIcon)(({ theme }) => {
  return `
  padding-top: 3px;
  padding-left: ${theme.space.space2};
`;
});
const StyledArrowDown = styled(ArrowDownIcon)(({ theme }) => {
  return `
  padding-top: 3px;
  padding-left: ${theme.space.space2};
`;
});

interface EmailAndLegacyVcsLoginProps {
  primaryBtn: string;
  secondaryBtn: string;
  primaryBtnDropdown1: string;
  primaryBtnDropdown2: string;
  displayWelcomeBackContent: boolean;
  setDisplayWelcomeBackContent: (input: boolean) => void;
}

const EmailAndLegacyVcsLogin = ({
  primaryBtn,
  secondaryBtn,
  primaryBtnDropdown1,
  primaryBtnDropdown2,
  displayWelcomeBackContent,
  setDisplayWelcomeBackContent,
}: EmailAndLegacyVcsLoginProps) => {
  const intl = useIntl();
  const query = new Map();
  const [openVcsDropdown, setOpenVcsDropdown] = useState(false);
  const bitbucketPath = intl.formatMessage(linkMessages.loginBitBucket);

  const router = useRouter(),
    queryParams = getQueryParams(router.asPath || ''),
    returnTo = queryParams.get('return-to');

  if (returnTo) {
    query.set('return-to', returnTo.toString());
  }

  const trackLegacyVcsDropdown = useTrackAction({
    context: 'logIn',
    object: 'legacyVcsDropdown',
  });
  return (
    <Flex flexDirection={'column'}>
      <LogInCtaFlow
        textId={'vcsAuthorized.hero.emailBtn'}
        query={query}
        displayLogInOptions={displayWelcomeBackContent}
        setDisplayLogInOptions={() => {
          return setDisplayWelcomeBackContent(!displayWelcomeBackContent);
        }}
      />
      {displayWelcomeBackContent && (
        <LegacyVcsDropdownTitle
          data-testid='legacy-vcs-dropdown'
          flexDirection={'row'}
          flexWrap={'wrap'}
          justifyContent={'start'}
          alignItems={'start'}
          onClick={() => {
            setOpenVcsDropdown(!openVcsDropdown);
            trackLegacyVcsDropdown('clicked');
          }}
        >
          <FormattedMessage id={'vcsAuthorized.hero.legacySignUpHelperPt1'} />
          <Flex gap={'space8'}>
            <FormattedMessage id={'vcsAuthorized.hero.legacySignUpHelperPt2'} />
            {openVcsDropdown ? (
              <StyledArrowUp size={'16px'} />
            ) : (
              <StyledArrowDown size={'16px'} />
            )}
          </Flex>
        </LegacyVcsDropdownTitle>
      )}
      {openVcsDropdown && (
        <Flex
          flexDirection={'column'}
          marginTop={'space32'}
          gap={'space16'}
        >
          <AuthButtonGitHub
            textId={primaryBtn}
            dropdown1={primaryBtnDropdown1}
            dropdown2={primaryBtnDropdown2}
            query={query}
            style='outlined'
            renderDropDown={true}
          />
          <AuthButtonBitbucket
            destination={setQueryParam(bitbucketPath, query)}
            textId={secondaryBtn}
            style='outlined'
          />
        </Flex>
      )}
    </Flex>
  );
};

export default EmailAndLegacyVcsLogin;
