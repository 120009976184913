import * as React from 'react';

import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import YouTube from 'react-youtube';

import { trackAction } from '~/backplane/analytics';
import Play from '~/components/icons/Play';
import * as colors from '~/components/shared/colors';
import * as fonts from '~/components/shared/typography';
import useOpenClose from '~/hooks/useOpenClose';
import { assetPrefix } from '~/pages/_document';

import Modal from './Modal';

const BackgroundImage = styled.div`
  background-image: url('${assetPrefix}/static/images/whatIsCircleCI.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: ${(320 / 570) * 100}%;
  margin: 30px 0 ${fonts.sizes.fs400};
  position: relative;
`;

const ButtonReset = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  color: ${colors.black100};
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: ${colors.blue100};
  }
`;

const PlayWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  background: radial-gradient(
    circle at 50%,
    ${colors.white},
    ${colors.white} 50%,
    rgba(255, 255, 255, 0) 50%
  );
  border-radius: 50%;
  overflow: hidden;
`;

interface TreatmentProps {
  videoId: string;
  variation: string;
}

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const VideoThumbnail = ({ videoId, variation }: TreatmentProps) => {
  const intl = useIntl();
  const { isOpen, toggle, close } = useOpenClose({ initialState: false });
  const location = `video on the ${capitalizeFirstLetter(variation)} page`;
  const handleClick = () => {
    toggle();
    trackAction('click-outer-cta', {
      cta_text: 'Watch the video',
      location: location,
    });
  };
  return (
    <>
      <BackgroundImage>
        <ButtonReset
          onClick={() => {
            return handleClick();
          }}
          data-optimizely={`clicked-outer-cta`}
        >
          <PlayWrapper>
            <Play size={60} />
          </PlayWrapper>
        </ButtonReset>
      </BackgroundImage>
      <Modal
        isOpen={isOpen}
        onClose={close}
      >
        <YouTube
          videoId={videoId}
          opts={{
            width: '640',
            height: '460',
            playerVars: {
              autoplay: 1,
              hl: intl.locale,
              modestbranding: 1,
              origin: 'circleci.com',
              rel: 0,
            },
          }}
        />
      </Modal>
    </>
  );
};

export default VideoThumbnail;
