import * as React from 'react';

import { useTrackAction } from '@circleci/analytics';
import { BitbucketDarkIcon, PrimaryButton } from '@circleci/compass';
import { useGoogleReCaptchaV2 } from 'react-google-recaptcha-hooks';
import { useIntl } from 'react-intl';

import { setQueryParam } from '~/components/shared/Link';

import ButtonContents from './ButtonContents';

interface BitbucketButtonProps {
  destination: string;
  textId: string;
  style: string;
}

const BitbucketButton: React.FC<BitbucketButtonProps> = ({
  destination,
  textId,
  style,
}) => {
  const intl = useIntl();
  const { executeReCaptcha, resetReCaptcha } = useGoogleReCaptchaV2({
    siteKey: process.env.RECAPTCHA_V2_SITE_KEY ?? '',
    language: intl.locale,
  });
  const trackButton = useTrackAction({
    context: 'authentication',
    object: 'button',
    eventProperties: {
      button: 'bitbucket',
    },
  });

  const onClickSuccess = (token: string): void => {
    const tokenParam = new Map();
    tokenParam.set('recaptcha', token);
    const destinationWithToken = setQueryParam(destination, tokenParam);

    window.location.replace(destinationWithToken);
  };

  const handleCaptcha = async (): Promise<void> => {
    const token = await executeReCaptcha();
    onClickSuccess(token);

    setTimeout(() => {
      resetReCaptcha();
    }, 3000);
  };

  const handleClick = (): void => {
    trackButton('clicked', {}, { server: true });
    if (!global.isSyntheticBot) {
      handleCaptcha();
    }
  };

  const chosenStyle = style || 'blue';

  return (
    <>
      <PrimaryButton
        key={chosenStyle}
        data-cy={`bitbucket-button-${chosenStyle}`}
        data-optimizely='bitbucket-button'
        onPress={handleClick}
      >
        <ButtonContents
          Icon={BitbucketDarkIcon}
          textId={textId}
        />
      </PrimaryButton>
    </>
  );
};

export default BitbucketButton;
