import * as React from 'react';

import { useTrackAction } from '@circleci/analytics';
import { UseTrackActionProps } from '@circleci/analytics/build/src/hooks/useTrackAction';
import { GitHubIcon } from '@circleci/compass';

import linkMessages from '~/links/messages';

import { trackButtonAction, UseTranslatedHref } from '../shared/Link';
import ButtonContents from './ButtonContents';
import { ButtonGroup, LoginButton } from './ButtonGroup';
import { ButtonGroupDropdown } from './ButtonGroupDropdown';

type ButtonStyles = 'green' | 'neutral' | 'outlined';

interface GitHubButtonProps {
  textId: string;
  dropdown1: string;
  dropdown2: string;
  query: Map<string, string>;
  className?: string;
  style?: ButtonStyles;
  renderDropDown?: boolean;
}
export interface AuthButtonDropDownItem {
  testId: string;
  hrefTranslation: {
    id: string;
    defaultMessage: string;
  };
  eventProps: UseTrackActionProps;
  textId: string;
}

const GitHubButton: React.FC<GitHubButtonProps> = ({
  textId,
  dropdown1,
  dropdown2,
  query,
  className,
  style,
}) => {
  const chosenStyle = style || 'neutral';
  const githubEventAll = {
    context: 'authentication',
    object: 'button',
    eventProperties: {
      button: 'github',
      githubScope: 'private',
    },
  };
  const githubEventPublic = {
    context: 'authentication',
    object: 'button',
    eventProperties: {
      button: 'github',
      githubScope: 'public',
    },
  };

  const trackAllReposEvent = useTrackAction(githubEventAll);
  const loginHref = UseTranslatedHref(linkMessages.login, undefined, query);
  return (
    <ButtonGroup
      // This is used for datadog synthetics test
      data-cy={`github-button-${chosenStyle}`}
      className={className}
      data-optimizely='github-button'
    >
      <LoginButton
        href={loginHref}
        onPress={trackButtonAction(loginHref, trackAllReposEvent)}
      >
        <ButtonContents
          Icon={GitHubIcon}
          textId={textId}
        />
      </LoginButton>
      <ButtonGroupDropdown
        query={query}
        testId={`auth-button-github-dropdown-${chosenStyle}`}
        firstItem={{
          testId: 'allReposItem',
          hrefTranslation: linkMessages.login,
          eventProps: githubEventAll,
          textId: dropdown1,
        }}
        secondItem={{
          testId: 'public-repo-item',
          hrefTranslation: linkMessages.loginPublic,
          eventProps: githubEventPublic,
          textId: dropdown2,
        }}
      />
    </ButtonGroup>
  );
};

export default GitHubButton;
