import * as React from 'react';

import { Flex } from '@circleci/compass';
import styled from '@emotion/styled';
import { FormattedMessage, useIntl } from 'react-intl';

import * as screenSizes from '~/components/shared/screenSizes';
import * as fonts from '~/components/shared/typography';

import {
  Asana,
  Figma,
  Hashicorp,
  Line,
  Nextdoor,
  Softbank,
  Misumi,
  Salesforce,
} from './logos';

const Lede = styled('h3')<{ isVcsAuthorized?: boolean }>(
  ({ isVcsAuthorized }) => {
    return `
margin: 24px 0 0;
font-size: ${fonts.sizes.fs500};
font-weight: ${fonts.weights.fw500};
line-height: 1.5;
letter-spacing: 3px;
text-transform: uppercase;

${
  isVcsAuthorized &&
  `
  font-weight: ${fonts.weights.fw600};
  `
}

${screenSizes.mediaMaxWidthMedium} {
  text-align: center;
}
`;
  },
);

const Logos = styled(Flex)`
  ${screenSizes.mediaMaxWidthXtraSmall} {
    display: grid;
    grid-template-areas:
      'a'
      'b'
      'c'
      'd';
    justify-content: space-around;

    @media (min-width: 350px) {
      grid-template-areas:
        'a b'
        'c d';
    }
  }

  .tb-logo:nth-of-type(1) {
    grid-area: a;
  }
  .tb-logo:nth-of-type(2) {
    grid-area: b;
  }
  .tb-logo:nth-of-type(3) {
    grid-area: c;
  }
  .tb-logo:nth-of-type(4) {
    grid-area: d;
  }
`;

const TrustedBy = ({ page }: { page: string }) => {
  return (
    <Flex
      direction='vertical'
      gap='space28'
      alignSelf='stretch'
      data-optimizely='signup-trusted-by'
    >
      <Lede isVcsAuthorized={page === 'vcsAuthorized'}>
        <FormattedMessage
          id='trustedBy'
          defaultMessage='Trusted by more than 30,000 organizations'
        />
      </Lede>
      <Logos
        alignItems='center'
        justifyContent='space-between'
        columnGap='space28'
        rowGap='space28'
        wrap={false}
        data-optimizely='signup-trusted-by-logos'
      >
        <Logo
          enLogo={<Nextdoor />}
          jaLogo={
            <Line
              width={85}
              height={30}
            />
          }
        />
        <Logo
          enLogo={<Figma />}
          jaLogo={<Softbank />}
        />
        <Logo
          enLogo={<Hashicorp />}
          jaLogo={<Misumi />}
        />
        <Logo
          enLogo={<Asana />}
          jaLogo={<Salesforce />}
        />
      </Logos>
    </Flex>
  );
};

const Logo = ({
  enLogo,
  jaLogo,
  koLogo,
}: {
  enLogo: JSX.Element;
  jaLogo: JSX.Element;
  koLogo?: JSX.Element;
}) => {
  const intl = useIntl();
  function localeLogo(locale) {
    switch (locale) {
      case 'ja':
        return jaLogo;
      case 'ko':
        return koLogo || enLogo;
      default:
        return enLogo;
    }
  }

  return (
    <Flex
      className='tb-logo'
      flexShrink={1}
      justifyContent='center'
      data-optimizely='signup-trusted-by-logo'
    >
      {localeLogo(intl.locale)}
    </Flex>
  );
};

export default TrustedBy;
