import * as React from 'react';

import {
  ErrorAlert,
  InfoAlert,
  SuccessAlert,
  AlertTitle,
  AlertContent,
  Flex,
} from '@circleci/compass';
import { useGoogleReCaptchaV2 } from 'react-google-recaptcha-hooks';
import { FormattedMessage, useIntl } from 'react-intl';

import AuthButtons from '~/components/AuthButtons';
import * as colors from '~/components/shared/colors';
import Disclaimer from '~/components/shared/Disclaimer';
import * as screenSizes from '~/components/shared/screenSizes';
import * as fonts from '~/components/shared/typography';
import TrustedBy from '~/components/TrustedBy';
import { assetPrefix } from '~/pages/_document';
import styled from '~/styled';

import VideoThumbnail from './VideoThumbnail';

const HeroSection = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: center;
  min-height: 100vh;

  .grecaptcha-badge {
    visibility: hidden;
  }

  @media screen and (min-width: ${screenSizes.medium}) {
    text-align: left;
  }

  ${screenSizes.mediaMaxWidthMedium} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const Left = styled.div(({ theme }) => {
  return `
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    width: 100%;
    background: ${theme.colors.cg800};
    padding: 110px min(7vw, 90px) 50px;
    position: relative;

    & > * {
      position: relative;
      z-index: 1;
    }

    .bg-illustration {
      display: none;
    }

    @media screen and (min-width: ${screenSizes.medium}) {
      align-items: stretch;
      padding: 110px min(20vw, 90px) 80px;
    }

    @media screen and (min-width: ${screenSizes.large}) {
      justify-content: space-around;
      width: 62%;
      padding: 80px min(9vw,90px) 303px 0;

      .bg-illustration {
        display: block;
        position: absolute;
        bottom: 0;
        right: -360px;
        z-index: 0;
      }
    }

    @media screen and (min-width: ${screenSizes.huge}) {
      .bg-illustration {
        right: -239px;
      }
    }
  `;
});

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.lang-ja p {
    font-size: ${fonts.sizes.fs700};
    margin-bottom: 15px;
  }

  @media screen and (min-width: ${screenSizes.medium}) {
    align-items: flex-start;
  }

  @media screen and (min-width: ${screenSizes.large}) {
    justify-content: center;
  }

  ${screenSizes.mediaMaxWidthMedium} {
    .twitter-container {
      text-align: center;
    }
  }

  ${screenSizes.mediaMaxWidthXtraSmall} {
    .twitter-container {
      text-align: left;
    }
  }

  @media screen and (max-width: 420px) {
    &.lang-ko br {
      display: none;
    }
  }
`;

const Right = styled.div`
  background: white;
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: min(8vw, 50px) min(7vw, 90px) 70px;

  .elements-container {
    display: block;
    width: min(100%, 325px);
  }

  @media screen and (min-width: ${screenSizes.small}) {
    padding: min(8vw, 50px) 0 70px;
  }

  @media screen and (min-width: ${screenSizes.large}) {
    flex: initial;
    width: 38%;
    align-items: center;
    justify-content: center;
    padding: 70px 0 303px 35px;
  }

  @media screen and (min-width: 1500px) {
    align-items: flex-end;
  }
`;

const Header = styled('h1')(({ theme }) => {
  return `
  font-family: 'Space Grotesk', Inter, sans-serif;
  font-size: 76px;
  font-weight: ${theme.fontWeights.bold};
  line-height: 1.15;
  text-transform: lowercase;
  margin: 0 0 0.56em;

  @media screen and (min-width: ${screenSizes.medium}) {
    font-size: 100px;
  }

${screenSizes.mediaMaxWidthMedium} {
  text-align: center;
  width: 100%;
}

${screenSizes.mediaMaxWidthXtraSmall} {
  font-size: 48px;
  text-align: left;
}
`;
});

const Subheader = styled('h2')(({ theme }) => {
  return `
  font-size: ${theme.fontSizes.h1};
  font-weight: ${theme.fontWeights.regular};
  margin: 0 0 0.56em;
  @media screen and (min-width: ${screenSizes.medium}) {
    font-size: 57px;
  }

  ${screenSizes.mediaMaxWidthMedium} {
    text-align: center;
  }

  ${screenSizes.mediaMaxWidthXtraSmall} {
    text-align: left;
  }
  `;
});

const Paragraphs = styled('div')(({ theme }) => {
  return `
  ${screenSizes.mediaMaxWidthMedium} {
    width: 100%;
  }

  p {
    line-height: 1.6;
    font-size: ${theme.fontSizes.body};
    margin: 0;

    @media screen and (min-width: ${screenSizes.medium}) {
      font-size: 18px;
    }

    ${screenSizes.mediaMaxWidthMedium} {
      text-align: center;
    }

    ${screenSizes.mediaMaxWidthXtraSmall} {
      text-align: left;
    }
  }
`;
});

const AlertWrapper = styled.div`
  background-color: ${colors.white100};

  & + & {
    margin-top: 24px;
  }
`;

const AlertBallast = styled.div`
  display: none;

  @media screen and (min-width: ${screenSizes.large}) {
    display: block;
    height: 70px;
    flex-shrink: 0;
  }
`;

const AuthButtonsContainer = styled(Flex)`
  @media (min-width: ${screenSizes.large}) {
    height: 450px;
  }
`;

interface PageProps {
  page: 'signup' | 'vcsAuthorized';
  variation?: 'stackoverflow' | null;
  error?: boolean;
  isSessionExpired?: boolean;
  isEmailVerifiedEvent?: boolean;
}

const Hero: React.FC<PageProps> = ({
  page,
  variation,
  error,
  isSessionExpired,
  isEmailVerifiedEvent,
}) => {
  const intl = useIntl();
  const locale = intl.locale;
  const i18nIdPrefix = variation ? `${page}-${variation}.hero` : `${page}.hero`;
  const videoId = intl.formatMessage({
    id: `${i18nIdPrefix}.videoId`,
    defaultMessage: 'uD6iNU-SESE',
  });

  const { ReCaptchaBadge } = useGoogleReCaptchaV2({
    siteKey: process.env.RECAPTCHA_V2_SITE_KEY ?? '',
    language: intl.locale,
  });

  const showTrustedBy = false;

  const hasAlert = error || isSessionExpired || isEmailVerifiedEvent;
  const omitSubtitle =
    page === 'vcsAuthorized' && (locale === 'en' || locale === 'ja');

  return (
    <HeroSection>
      <Left>
        {hasAlert && (
          <div>
            {error && (
              <AlertWrapper>
                <ErrorAlert>
                  <AlertTitle>
                    <FormattedMessage id='error.title' />
                  </AlertTitle>
                  <AlertContent>
                    <FormattedMessage id='error.content' />
                  </AlertContent>
                </ErrorAlert>
              </AlertWrapper>
            )}
            {isSessionExpired && page === 'vcsAuthorized' && (
              <AlertWrapper>
                <InfoAlert dismissible>
                  <AlertTitle>
                    <FormattedMessage
                      id={`${i18nIdPrefix}.alert.sessionExpired.title`}
                    />
                  </AlertTitle>
                  <AlertContent>
                    <FormattedMessage
                      id={`${i18nIdPrefix}.alert.sessionExpired.content`}
                    />
                  </AlertContent>
                </InfoAlert>
              </AlertWrapper>
            )}
            {isEmailVerifiedEvent && page === 'vcsAuthorized' && (
              <AlertWrapper>
                <SuccessAlert>
                  <AlertTitle>
                    <FormattedMessage
                      id={`${i18nIdPrefix}.alert.emailVerified.title`}
                    />
                  </AlertTitle>
                  <AlertContent>
                    <FormattedMessage
                      id={`${i18nIdPrefix}.alert.emailVerified.content`}
                    />
                  </AlertContent>
                </SuccessAlert>
              </AlertWrapper>
            )}
          </div>
        )}
        <LeftContent className={`lang-${intl.locale}`}>
          <Header data-optimizely='signup-h1'>
            &gt;
            <FormattedMessage id={`${i18nIdPrefix}.header`} />
          </Header>
          {page == 'signup' && (
            <Subheader>
              <FormattedMessage id={`${i18nIdPrefix}.subheader`} />
            </Subheader>
          )}
          {!omitSubtitle && (
            <Paragraphs data-optimizely='signup-p'>
              <FormattedMessage
                id={`${i18nIdPrefix}.paragraph`}
                tagName='p'
              />
            </Paragraphs>
          )}

          {variation && (
            <VideoThumbnail
              videoId={videoId}
              variation={variation}
            />
          )}
          {showTrustedBy && <TrustedBy page={page} />}
        </LeftContent>
        {hasAlert && <AlertBallast />}

        <img
          className='bg-illustration'
          height='303'
          width='376'
          alt=''
          role='presentation'
          src={`${assetPrefix}/static/images/jelly-pixel_bubble-cropped.svg`}
        />
      </Left>
      <Right>
        <AuthButtonsContainer
          marginTop={'space48'}
          className='elements-container'
        >
          <AuthButtons
            data-cy='auth-buttons-large-layout'
            primaryBtn={`${i18nIdPrefix}.primaryBtn`}
            secondaryBtn={`${i18nIdPrefix}.secondaryBtn`}
            titleBtns={`${i18nIdPrefix}.titleBtns`}
            primaryBtnDropdown1={`${i18nIdPrefix}.primaryBtnDropdown1`}
            primaryBtnDropdown2={`${i18nIdPrefix}.primaryBtnDropdown2`}
            gitlabBtn={`${i18nIdPrefix}.gitlabBtn`}
            signupBtn={`${i18nIdPrefix}.signupBtn`}
            page={page}
          />
          <Disclaimer page={page} />
        </AuthButtonsContainer>
      </Right>
      {!global.isSyntheticBot && ReCaptchaBadge}
    </HeroSection>
  );
};

export default Hero;
